//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { routerAsync } from '@/app-module';

import { FormSchema } from '@/shared/form/form-schema';
import { UserModel } from '@/modules/auth/user-model';
const { fields } = UserModel;
const formSchema = new FormSchema([
  fields.id,
  fields.email,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsIam,
  fields.roles,
  fields.countryCode,
  fields.accountType,
]);

export default {
  name: 'app-iam-edit-page',

  props: ['id'],
  components: {
    VuePhoneNumberInput,
  },

  async created() {
    await this.doFind(this.id);
    this.model = formSchema.initialValues(this.record);
    this.model.countryCode = this.countryCode
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      options: fields.roles.options,
    };
  },
  computed: {
    ...mapGetters({
      record: 'iam/form/record',
      findLoading: 'iam/form/findLoading',
      saveLoading: 'iam/form/saveLoading',
      currentLocation: 'layout/currentLocation'
    }),
    fields() {
      return fields;
    },
    isRTL() {
      return localStorage.getItem('language') == 'ar'
    },
    countryCode() {
      return this.currentLocation['countryCode'] || 'SA'
    },
  },
  methods: {
    ...mapActions({
      doFind: 'iam/form/doFind',
      doUpdate: 'iam/form/doUpdate',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    onUpdate(data) {
      this.model.countryCode = data.countryCode;
    },
    doReset() {
      routerAsync().push('/Admins');
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      this.model.accountType = 'admin';
      const values = formSchema.cast(this.model);
      this.doUpdate(values);
    },
  },
};
